import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ApiServiceService } from './api-service.service';
import { environment } from '../../environments/environment';
import { User } from '../models/auth/user';
import { ConfigService } from '../helpers/config.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  menuLoaded = new BehaviorSubject<any>(false);
  generalRefreshDetails = new BehaviorSubject<boolean>(false);

  public currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private _config: ConfigService,
    private apiService: ApiServiceService
  ) {
    const authLS = localStorage.getItem('authUser');
    const ls = authLS ? JSON.parse(authLS) as User : '';
    if (ls) {
      this.currentUserSubject.next(ls);
    }
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  login(data: any): any {
    return this.apiService.post(`${environment.apiUrl}/login`, data)
      .pipe(map(res => {
        let user = res.data.user;
        this.currentUserSubject.next(user);
        localStorage.setItem('authUser', JSON.stringify(user));
        localStorage.setItem('vendorId', JSON.stringify(user.vendor_id));

        localStorage.setItem("token", JSON.stringify(res.data.token));
        this._config.goTo("/admin/profile");
      }), catchError(this.handleError));
  }
  logout() {
    this.clearUserData();
    this.router.navigate(['/']);
  }
  clearUserData() {
    localStorage.removeItem('authUser');
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }

  handleError(error: any) {
    return throwError(error);
  }

}
